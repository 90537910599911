// extracted by mini-css-extract-plugin
export var main = "styles-module--main--fdfyh";
export var headerSection = "styles-module--headerSection--7F7zJ";
export var numberSection = "styles-module--numberSection--mFiHx";
export var textHeader = "styles-module--textHeader--pke2b";
export var container = "styles-module--container--8ds+h";
export var wireframes = "styles-module--wireframes--C5Xmq";
export var blackoutWrapper = "styles-module--blackoutWrapper--gRct-";
export var myBudgetWrapper = "styles-module--myBudgetWrapper--xhbNz";
export var myBudgetBlock = "styles-module--myBudgetBlock--a6ddY";
export var topText = "styles-module--topText--XGwtp";
export var bottomText = "styles-module--bottomText--GUjuZ";
export var imageMyBudget = "styles-module--imageMyBudget--aetG9";
export var wrapper = "styles-module--wrapper--y8MaP";
export var idea = "styles-module--idea--amtSn";
export var contentIdea = "styles-module--contentIdea--P1lKW";
export var text = "styles-module--text--4AJpV";
export var scope = "styles-module--scope--KllJz";
export var contentScope = "styles-module--contentScope--BlBTu";
export var scopeBlock = "styles-module--scopeBlock--ciJfq";
export var icon = "styles-module--icon--X6Sw8";
export var name = "styles-module--name--CLZJk";
export var userFlow = "styles-module--userFlow--If6JJ";
export var userFlowContent = "styles-module--userFlowContent--r9sMI";
export var gridSystem = "styles-module--gridSystem--BSGak";
export var littleBit = "styles-module--littleBit--LCNRm";
export var descriptionBlock = "styles-module--descriptionBlock--KP6tD";
export var row = "styles-module--row---uYaF";
export var content = "styles-module--content--zze9e";
export var image = "styles-module--image--l3W5q";
export var addCards = "styles-module--addCards--JCxjw";
export var addCardsContent = "styles-module--addCardsContent--aswVE";
export var thankYou = "styles-module--thankYou--TiPPp";
export var footer = "styles-module--footer--M7ujU";
export var line = "styles-module--line--1n099";