import React from 'react'

import {descriptionDataBlock, scopeBlocksData} from "./myBudget.constants";

import * as styles from './styles.module.scss'
import iphone12 from './../../assets/images/png/myBudget/iphone12.png'
import ideaImg from './../../assets/images/png/myBudget/girl.png'
import userFlow from './../../assets/images/png/myBudget/userFlow.png'
import wireframes from './../../assets/images/png/myBudget/wireframesImage.png'
import iphone12x3 from './../../assets/images/png/myBudget/iphone12x3.png'
import gridSystem from './../../assets/images/png/myBudget/gridSystem.png'
import addCards from './../../assets/images/png/myBudget/addCards.png'


const MyBudget = () => {
	return (
		<main className={styles.main}>
			<div className={styles.container}>
				<div className={styles.myBudgetWrapper}>
					<div className={styles.myBudgetBlock}>
						<div>
							<span className={styles.topText}>UX/UI Design</span>
						</div>
						<div>
						<h1>My budget</h1>
						<span className={styles.bottomText}>mobile app</span>
						</div>
					</div>
					<div className={styles.imageMyBudget}>
						<img src={iphone12} alt='my Budget'/>
					</div>
				</div>
				<div className={styles.wrapper}>
					<section className={styles.idea}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>01-07</span>
							<h1>A little bit about the idea
								of the application</h1>
						</header>
						<div className={styles.contentIdea}>
							<div className={styles.imageIdea}>
								<img src={ideaImg} alt='Many banks, many cards, many accounts. '/>
							</div>
							<div className={styles.text}>
								<h3>
									Many banks, many cards, many accounts.
								</h3>
								<p>
									How not to get lost in a world where every day we make a lot of money transactions?
									There is a solution! And this is MY BUDGET.
									It will help you put all your money together and come up with a strategy to save your budget.
								</p>
							</div>
						</div>
					</section>
					<section className={styles.scope}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>02-07</span>
							<h1>
								The scope
								of our work
							</h1>
						</header>
						<div className={styles.contentScope}>
							{scopeBlocksData.map(block => {
								return (
									<div key={block.id} className={styles.scopeBlock}>
										<div className={styles.icon} style={{background: block.background}}>
											<img src={block.img} alt={block.text}/>
										</div>
										<span className={styles.name}>{block.text}</span>
									</div>
								)
							})}
						</div>
					</section>
					<section className={styles.userFlow}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>03-07</span>
							<h1>
								User flow
							</h1>
							<span className={styles.textHeader}>
								The structure of the application allows you to cover all the areas necessary to achieve the main goal - budget savings.
							</span>
						</header>
						<div className={styles.userFlowContent}>
							<img src={userFlow} alt='User flow'/>
						</div>
					</section>
				</div>
				<section className={styles.wireframes}>
					<header className={styles.headerSection}>
						<span className={styles.numberSection}>04-07</span>
						<h1>
							Wireframes
						</h1>
						<span className={styles.textHeader}>
								We have created more than 20+ screens
							</span>
					</header>
					<div className={styles.userFlowContent}>
						<img src={wireframes} alt='Wireframes'/>
						<div className={styles.blackoutWrapper}>
						</div>
					</div>
				</section>
				<div className={styles.wrapper}>
					<section className={styles.gridSystem}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>05-07</span>
							<h1>
								Grid system
							</h1>
							<span className={styles.textHeader}>
								We used a simple but highly functional grid system 8px.
								Сolums: 2 / Margin: 24px / Gutter: 15px / Width: 156px
							</span>
						</header>
						<div className={styles.userFlowContent}>
							<img src={gridSystem} alt='Grid system'/>
						</div>
					</section>
					<section className={styles.littleBit}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>06-07</span>
							<h1>
								A little bit about the functionality
								of the application
							</h1>
						</header>
						<div className={styles.userFlowContent}>
							<img src={iphone12x3} alt='A little bit about the functionality of the application'/>
						</div>
					</section>
					{descriptionDataBlock.map(block => {
						return (
							<section key={block.id} className={styles.descriptionBlock}>
								<div className={styles.row}>
									<div className={styles.content}>
										<div className={styles.icon} style={{backgroundColor: block.icon1Background}}>
											<img src={block.icon1} alt={block.headerText1}/>
										</div>
										<h3>
											{block.headerText1}
										</h3>
										<p>
											{block.text1}
										</p>
									</div>
									<div className={styles.image}>
										<img src={block.img1} alt={block.headerText1}/>
									</div>
								</div>
								<div className={styles.row}>
									<div className={styles.image}>
										<img src={block.img2} alt={block.headerText2}/>
									</div>
									<div className={styles.content}>
										<div className={styles.icon} style={{backgroundColor: block.icon2Background}}>
											<img src={block.icon2} alt={block.headerText2}/>
										</div>
										<h3>
											{block.headerText2}
										</h3>
										<p>
											{block.text2}
										</p>
									</div>
								</div>
								{block.middleImg
									?
									<div>
										<img src={block.middleImg} alt='Many phones'/>
									</div>
									:
									''}
							</section>
						)
					})}
					<section className={styles.addCards}>
						<header className={styles.headerSection}>
							<span className={styles.numberSection}>07-07</span>
							<h1>
								Add cards
								of any banks
							</h1>
						</header>
						<div className={styles.addCardsContent}>
							<img src={addCards} alt='Add cards of any banks'/>
						</div>
					</section>
					<div className={styles.thankYou}>
						<span>
							thank you
						</span>
					</div>
				</div>
				<footer className={styles.footer}>
					<div className={styles.line}></div>
					<span>
					All materials were used for non-commercial purposes and belong to its owners.
				</span>
				</footer>
			</div>
		</main>)
}

export default MyBudget