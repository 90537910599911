import folder from './../../assets/images/svg/myBudget/Folder.svg'
import search from './../../assets/images/svg/myBudget/Search.svg'
import paper from './../../assets/images/svg/myBudget/Paper.svg'
import graph from './../../assets/images/svg/myBudget/Graph.svg'
import iphoneWithBudget from './../../assets/images/png/myBudget/iphoneWithBudget.png'
import iphoneOctober from './../../assets/images/png/myBudget/iphoneOctober.png'
import blueGraph from './../../assets/images/svg/myBudget/blueGraph.svg'
import home from './../../assets/images/svg/myBudget/Home.svg'
import monthlyBudgetIphone12 from './../../assets/images/png/myBudget/monthlyBudgetIphone12.png'
import storesIphone12 from './../../assets/images/png/myBudget/storesIphone12.png'
import document from './../../assets/images/svg/myBudget/Document.svg'
import calendar from './../../assets/images/svg/myBudget/Calendar.svg'
import manyIphones from './../../assets/images/jpg/myBudget/manyIphones12.jpg'

export const scopeBlocksData = [
	{
		id:1,
		background:'#FFA654',
		img:search,
		text:'Research'
	},
	{
		id:2,
		background:'#547BFF',
		img:folder,
		text:'User flow'
	},
	{
		id:3,
		background:'#61D13A',
		img:graph,
		text:'Wireframes'
	},
	{
		id:4,
		background:'#FF5454',
		img:paper,
		text:'UX / UI Design'
	},
]

export const descriptionDataBlock = [
	{
		id:1,
		img1:iphoneWithBudget,
		img2:iphoneOctober,
		icon1:home,
		icon2:blueGraph,
		icon1Background:'#FEF7EE',
		icon2Background:'#EEF3FE',
		headerText1:'Home screen',
		headerText2:'Statistics',
		text1:'The home page of the application contains information about your current total budget. And also about spending this month in four directions, which you can customize for yourself in the settings of your wallet. You choose a destination, set a budget limit, and then the app will tell you how much more you can spend, for example, on clothes.',
		text2:'Statistics clearly show the proportions of your spending in selected or all areas. Analyzing statistics will allow you to select areas for enhanced savings. ',
		middleImg:manyIphones,
	},
	{
		id:2,
		img1:monthlyBudgetIphone12,
		img2:storesIphone12,
		icon1:document,
		icon2:calendar,
		icon1Background:'#F0FEEE',
		icon2Background:'#FEEEEE',
		headerText1:'Planning',
		headerText2:'Monthly limit',
		text1:'Planning a budget will help you stay within the established limits. Gradually reduce the limit of inappropriate spending every month to avoid unnecessary purchases. And thereby save money.',
		text2:'The monthly limit will show how much money has already been spent and how much is left. This way, you can plan future purchases and avoid lightning-fast unnecessary expenses. Save in a structured and convenient way.',
	},
]